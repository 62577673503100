import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
// import { AuthContext } from "../../context/AuthContext";
import Cookies from "js-cookie";
import "./login.scss";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });
  const axiosinstance = axios.create({ baseURL: process.env.API_URL });
  const { loading, error, dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => {
      const updatedCredentials = { ...prev, [e.target.id]: e.target.value };
      console.log(updatedCredentials); // Log the updated state for debugging
      return updatedCredentials;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
  
    try {
      const res = await axiosinstance.post("https://www.api.somanymornings.com/api/auth/login", credentials);
      // Log the token
      console.log(res.data.access_token);
      if (res.data.isAdmin) {
        // Set the access token in cookies
        Cookies.set("access_token", res.data.access_token);

        // Assuming the access token is returned in the response
        const accessToken = res.data.access_token;

        // Set the access token in the axios headers
        axiosinstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
        navigate("/");
      } else {
        dispatch({
          type: "LOGIN_FAILURE",
          payload: { message: "You are not allowed!" },
        });
  
        // Remove authentication token from local storage
        // localStorage.removeItem("authToken");
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };

  return (
    <section className="login-page">
      <div className="row">
      <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
         <div className="login-form">
         <form onSubmit={handleSubmit}>
         <h3 className="Welcome-login">Login</h3>
         <div className="row">
           <div className="col-xxl-6 col-lg-6 col-md-6 col-12" id="left-form">
           <input
          type="text"
          placeholder="username"
          id="username"
          onChange={handleChange}
             />
           </div>
           <div className="col-xxl-6 col-lg-6 col-md-6 col-12" id="left-form">
           <input
          type="password"
          placeholder="password"
          id="password"
          onChange={handleChange}
              />
           </div>
           <div className="col-xxl-12 col-lg-12 col-md-12 col-12" id="left-form">
             {/* <p className="agree">I agree to all the <span><a href="/">Terms</a></span> and <span><a href="/">Privacy Policies</a></span></p> */}
           <button disabled={loading} className="register-btn">
          Login
        </button>
        {error && <p className="login-error">{error.message}</p>}
          </div>
         </div>
         </form>
         </div>
         </div>
      </div>
    </section>
  );
};

export default Login;
