import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from '../../context/AuthContext';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Pagination } from '@mui/material';
import './bookingList.css';

const BookingList = ({ userId }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingsPerPage] = useState(5); // Change this value to adjust items per page
  const { user, dispatch } = useContext(AuthContext);

  useEffect(() => {
    const fetchCompletedBookings = async () => {
      try {
        const response = await axios.get('https://www.api.somanymornings.com/api/books/completed-bookings');
        const completedBookings = response.data.data;
        setBookings(completedBookings);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching completed bookings:', error);
        setLoading(false);
      }
    };
  
    fetchCompletedBookings();
  }, []);
  
  // Previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Next page
  const handleNextPage = () => {
    if (currentPage < Math.ceil(bookings.length / bookingsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleCancelBooking = async (bookingId, roomId, reservationDates) => {
    try {
      // Make an HTTP request to cancel the booking
      const response = await axios.post('https://www.api.somanymornings.com/api/books/bookings/cancelbooking', {
        bookingid: bookingId,
        roomId: roomId,
      });

      // Check if the cancellation was successful
      if (response.data.success) {
        // Update the local state or perform any necessary actions
        // (e.g., refetch the list of bookings)
        console.log('Booking cancelled successfully');
        window.alert('Booking cancelled successfully');

        // Remove canceled reservation dates from room's unavailableDates
        await axios.put(`https://www.api.somanymornings.com/api/rooms/availability/${roomId}`, {
          dates: reservationDates,
          cancelBooking: true,
        });

        // Reload the page or fetch updated data as needed
        window.location.reload();
      } else {
        console.error('Failed to cancel booking:', response.data.error);
      }
    } catch (error) {
      console.error('Error cancelling booking:', error);
      window.alert('Error cancelling booking. Please try again.');
    }
  };

  // Logic for pagination
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = bookings.slice(indexOfFirstBooking, indexOfLastBooking).reverse();

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div class="datatableTitle" bis_skin_checked="1">
            <h3 className="listContainerhd">All Bookings Data</h3>
          </div>
          <div className="listContainer">
            <TableContainer component={Paper}>
              <Table className="bookingtable">
                <TableHead>
                  <TableRow>
                    <TableCell className="roomnu">Room No.</TableCell>
                    <TableCell className="usern">User Name</TableCell>
                    <TableCell className="reservationd">Reservation Dates</TableCell>
                    <TableCell className="pricep">Price</TableCell>
                    <TableCell className="bookingd">Booking Date</TableCell>
                    <TableCell className="roomn">Room Name</TableCell>
                    <TableCell className="usere">User Email</TableCell>
                    <TableCell className="userp">User Phone</TableCell>
                    <TableCell className="bookings">Booking Status</TableCell>
                    <TableCell className="actiona">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentBookings.map((booking) => (
                    <TableRow key={booking._id}>
                      <TableCell>{booking.roomNumber}</TableCell>
                      <TableCell>{booking.userName}</TableCell>
                      <TableCell>{booking.reservationDates && booking.reservationDates.length > 0
                         ? `${new Date(booking.reservationDates[0]).toLocaleDateString()} to ${new Date(booking.reservationDates[booking.reservationDates.length - 1]).toLocaleDateString()}`
                         : "N/A"}</TableCell>
                      <TableCell>{booking.price}</TableCell>
                      <TableCell>{booking.currentDate ? new Date(booking.currentDate).toLocaleDateString() : "N/A"}</TableCell>
                      <TableCell>{booking.title}</TableCell>
                      <TableCell>{booking.userEmail}</TableCell>
                      <TableCell>{booking.userPhone}</TableCell>
                      <TableCell>{booking.status}</TableCell>
                      <TableCell className="btn-table-data">
                        {booking.status !== 'Cancelled' && new Date(booking.reservationDates[booking.reservationDates.length - 1]) >= new Date() ? (
                          <Button className="Cancel-btn" onClick={() => handleCancelBooking(booking._id, booking.roomId)}>
                            Cancel
                          </Button>
                        ) : (
                          <Button className="Cancel-btn" disabled>
                            {booking.status === 'Cancelled' ? 'Cancelled' : 'Booking Expired'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination */}
            <Pagination
              count={Math.ceil(bookings.length / bookingsPerPage)}
              page={currentPage}
              onChange={(event, page) => paginate(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingList;
